<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
        <v-chip v-if="item.main">
          MAIN
        </v-chip>
      </template>

      <!-- allow_guest -->
      <template #[`item.allow_guest`]="{ item }">
        <v-icon
          size="18"
          color="success"
        >
          {{ item.allow_guest ? icons.mdiCheckCircle : '' }}
        </v-icon>
        ({{ item.accept_last_hours }}hr)
      </template>

      <!-- nicename -->
      <template #[`item.nicename`]="{ item }">
        {{ item.nicename }}
      </template>

      <!-- max_participant -->
      <template #[`item.max_participant`]="{ item }">
        {{ item.max_participant }}
      </template>

      <!-- participant_rule_id -->
      <template #[`item.participant_rule_id`]="{ item }">
        {{ mapRule(item.participant_rule).title }}
      </template>

      <!-- templates -->
      <template #[`item.templates`]="{ item }">
        {{ item.templates ? item.templates.length : 'N/A' }}
        <v-chip
          v-if="!item.templates.find(tp => tp.type === 'activity_invitation.landing_page')"
          color="error"
        >
          NO LANDING PAGE!
        </v-chip>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <edit-dialog
                v-if="resource"
                :resource="resource"
                @updated="paginate"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { useParticipantRule } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiCheckCircle, mdiDelete, mdiDotsVertical } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Allow Guest', value: 'allow_guest' },
      { text: 'Nicename', value: 'nicename', sortable: false },
      { text: 'Max Participant', value: 'max_participant' },
      { text: 'Participant Rule', value: 'participant_rule_id' },
      { text: 'Templates', value: 'templates', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const { mapRule } = useParticipantRule()

    return {
      t,
      formatDate,
      mapRule,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiCheckCircle,
        mdiDotsVertical,
        mdiDelete,
      },
    }
  },
}
</script>
