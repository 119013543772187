<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Activity Rule
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.nicename"
              label="Name to display"
              outlined
              :disabled="disabledFields.includes('nicename')"
            />
            <v-text-field
              v-model="form.max_participant"
              type="number"
              label="Max Participants"
              outlined
              dense
              :disabled="disabledFields.includes('max_participant')"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.participant_rule_id"
              label="Requirements of Participant's info"
              :items="participantRuleOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.merchant_id"
              label="Merchant - activity provider"
              :items="merchantOptions"
              item-text="label"
              item-value="value"
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.options.clone_template_rule_id"
              label="(Optional) Clone Templates from rule"
              :items="activityRuleOptions"
              clearable
              item-text="label"
              item-value="value"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.accept_last_hours"
              type="number"
              label="Last hours for accept"
              outlined
              :disabled="disabledFields.includes('accept_last_hours')"
            />
          </v-col>

          <v-col cols="6">
            <v-switch
              v-model="form.allow_guest"
              label="Allow Guest (no need to login)"
              outlined
            />
            <v-switch
              v-model="form.main"
              label="Default Main Rule."
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { useActivityRule, useMerchant, useParticipantRule } from '@/composables'
import { t } from '@/plugins/i18n'
import { between, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    const defaultFormValue = ({
      nicename: useFieldValue('nicename', ''),
      max_participant: useFieldValue('max_participant', 1000),
      accept_last_hours: useFieldValue('accept_last_hours', 48),
      participant_rule_id: useFieldValue('participant_rule_id', null),
      merchant_id: useFieldValue('merchant_id', null),
      allow_guest: useFieldValue('allow_guest', true),
      main: useFieldValue('main', false),
      options: {
        clone_template_rule_id: null,
      },
    })

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      emit('close')
    }

    const { participantRuleOptions } = useParticipantRule()
    const { merchantOptions, fetchMerchants } = useMerchant()
    fetchMerchants({ take: 999 })

    const { activityRuleOptions, fetchActivityRules } = useActivityRule()
    fetchActivityRules({ take: 999 })

    return {
      participantRuleOptions,
      merchantOptions,
      activityRuleOptions,

      isEditing,

      form,
      formRef,

      // Rules
      validators: {
        required,
        between,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
