var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),(item.main)?_c('v-chip',[_vm._v(" MAIN ")]):_vm._e()]}},{key:"item.allow_guest",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"18","color":"success"}},[_vm._v(" "+_vm._s(item.allow_guest ? _vm.icons.mdiCheckCircle : '')+" ")]),_vm._v(" ("+_vm._s(item.accept_last_hours)+"hr) ")]}},{key:"item.nicename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nicename)+" ")]}},{key:"item.max_participant",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_participant)+" ")]}},{key:"item.participant_rule_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapRule(item.participant_rule).title)+" ")]}},{key:"item.templates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.templates ? item.templates.length : 'N/A')+" "),(!item.templates.find(function (tp) { return tp.type === 'activity_invitation.landing_page'; }))?_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" NO LANDING PAGE! ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadResource(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.resource)?_c('edit-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }